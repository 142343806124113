.statsmain {
    border-bottom: 1px solid var(--border2);
    padding: 30px 0px;

    .statshead {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 30px;
    }

    .statscardtwo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;

        .statcard {
            display: flex;
            padding: 20px 22px;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            gap: 20px;
            border-radius: 4px;
            background: var(--cardbg);

            .statcardmainimg {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            .statcardpara {
                color: var(--para);
                font-family: 'hregular';
                font-size: 14px;
                font-style: normal;
      
                line-height: 110%; /* 15.4px */
                letter-spacing: 0.1px;
                // text-transform: uppercase;
                // padding: 15px 0px;
                margin-bottom: 10px;
                z-index: 99;
            }

            .statcardhead {
                color:var(--cardtextmain);
                font-family: 'f37';
                font-size: 24px;
                font-style: normal;
            
                line-height: 110%; /* 26.4px */
                letter-spacing: 0.1px;
                z-index: 99;
            }
        }
    }

    .statscardthree {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 20px;

        .statcard {
            border: 1px solid #1B1B1B;
            background: rgba(13, 13, 13, 0.30);
            // box-shadow: 2px 2px 0px 0px #FFF;
            display: flex;
            padding: 40px 25px;
            // flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            position: relative;

            .statcardmainimg {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -9;
            }

            .statcardpara {
                color: #868686;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                // text-transform: uppercase;
                // padding: 15px 0px;
                margin-bottom: 10px;
                z-index: 99;
            }

            .statcardhead {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                z-index: 99;
            }
        }
    }
}





@media(max-width:992px) {
    .statsmain .statscardtwo {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .statsmain .statscardthree {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .statsmain .statshead {
        font-size: 20px;
        margin-bottom: 25px;
    }

    .statsmain .statscardtwo .statcard .statcardhead {
        font-size: 20px;
    }

    .statsmain .statscardthree .statcard .statcardhead {
        font-size: 20px;
    }

    .paddmobilezero {
        padding: 0px;
    }
    .statsmain .statscardtwo .statcard{
        flex-direction: column;
        padding: 30px 15px;

    }
    .statsmain .statscardtwo .statcard .statcardpara{
        font-size: 12px;
    }
    .statsmain .statscardtwo .statcard .statcardhead{
        font-size: 14px;
    }
    .main-banner .bannerheadings .stake-btn{
        max-width: 100%;
    }
}