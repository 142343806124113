.forheightset {
    min-height: 100vh;
}

.main-banner {
    min-height: calc(419px - 79px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 9;
    overflow: hidden;
    padding-top: 44px;
    position: relative;

    .global-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .second {
        position: absolute;
        right: 0px;
        bottom: 0px;
        object-fit: cover;
    }

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        height: 419px;
        pointer-events: none;
        object-fit: cover;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            padding-right: 50px;
        }
    }

    .bannerheadings {
        max-width: 494px;
        position: relative;

        .bannerhead {
            color: var(--V6-Dark-Theme-Green-Color);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.36px;
            text-transform: uppercase;

        }

        h2 {
            color: var(--heading-text);
            font-size: 55px;
            font-family: "f37";
            font-style: normal;
            line-height: 110%;

            span {
                color: var(--heading-text);
                font-size: 55px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                font-family: "bl";
            }
        }

        .bannerpara {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-family: 'hregular';
            line-height: 120%;
            margin: 25px 0px;
        }

        .staketwobutton {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .stake {
                border-radius: 4px;
                background: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                padding: 18px 22px;
                max-width: 126px;
                width: 100%;
                color: #081519;
                font-family: 'hregular';
                font-size: 14px;
                font-style: normal;
                line-height: 100%;
                white-space: nowrap;
            }

            .greensss {
                border-radius: 4px;
                padding: 18px 22px;
                background: transparent;
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);
                font-family: 'hregular';
                font-size: 14px;
                font-style: normal;
                line-height: 100%;
            }
        }

        .twice-btns {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
        }

        .howitworks-btn {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        .stake-btn {
            background: #FFF;
            padding: 15px 10px 15px 12px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 199px;
            width: 100%;
        }
    }
}

.tabsmain {
    position: relative;
    background: var(--bgbgbg);
    padding: 30px 0px;

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-radius: 4px;
        background: var(--tabbg) !important;
        padding: 8px 20px !important;
        // color: #FFF !important;
        // border-bottom: 1px solid #FFF !important;
        color: var(--btn-green-bg) !important;
        font-size: 14px;
        font-style: normal;
        font-family: 'f37';
        line-height: 100%;
        border: none !important;
    }

    .nav-tabs {
        max-width: fit-content;
        border: none !important;
        margin-bottom: 10px;
        border-radius: 4px;
        background: var(--tabbgdull);
        padding: 4px 2px !important;

        .nav-item {
            .nav-link {

                display: flex;
                // padding: 20px 10px;
                justify-content: center;
                align-items: center;

                border: none;
                border-radius: 4px;
                // width: 198px;
                border-radius: 4px;
                background: var(--tabbgdull);
                padding: 8px 20px !important;
                color: var(--d-body-link-text);
                font-family: 'f37';
                font-size: 14px;
                font-style: normal;
                border: none;
                line-height: 100%;
            }
        }
    }
}

.forcards {
    background: #081519;
    padding: 30px 0px;
    border-bottom: 1px solid #142125;

    .parentscards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .innercard {
            border-radius: 4px;
            background: var(--V6-Dark-Theme-Box-Background-Color, #021C24);
            padding: 20px 22px;
            width: 100%;
            height: 133.61px;

            .mainimage {
                width: 23.169px;
                height: 22.61px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .innerimg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            p {
                color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
                font-size: 14px;
                font-style: normal;
                font-family: 'hregular';
                line-height: 110%;
                /* 15.4px */
                letter-spacing: 0.1px;
                margin-top: 20px;
                margin-bottom: 10px;
            }

            h6 {
                color: #E1F5EB;
                font-size: 24px;
                font-style: normal;
                font-family: 'f37';
                line-height: 110%;
                /* 26.4px */
                letter-spacing: 0.1px;
            }
        }
    }
}

@media (min-width:1800px) {
    .main-banner {
        min-height: calc(600px - 79px);
    }

    .main-banner .banner-bg {
        height: 600px;
    }
}

@media (max-width:992px) {
    .main-banner {
        min-height: calc(520px - 79px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        z-index: 9;
        overflow: hidden;

        .banner-bg {
            display: none;
        }

        .banner-bg-mbl {
            display: block !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            height: 520px;
            pointer-events: none;
            object-fit: cover;
        }
    }



    .main-banner .bannerheadings .bannerpara {
        font-size: 16px;
    }

    .main-banner .bannerheadings {
        // max-width: 305px;
        width: 100%;
        text-align: center;
    }

    .tabsmain .nav-tabs .nav-item {
        padding-bottom: 1px;

    }

    .tabsmain .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        white-space: nowrap;
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        border-bottom: unset !important;
    }

    .tabsmain .nav-tabs .nav-item.show .nav-link,
    .tabsmain .nav-tabs .nav-link.active {
        // border-bottom: 1px solid #fff !important;
    }



    .tabsmain .nav-tabs .nav-item .nav-link {
        width: 100%;
        font-size: 14px;
        padding: 20px !important;
    }

    .tabsmain .nav-tabs {
        border-bottom: 1px solid #3B3B3B;
    }

    .tabsmain .nav-tabs {
        width: 100%;
        max-width: 100%;
    }

    .tabsmain .nav-tabs {
        margin-bottom: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .tabsmain .nav-tabs .nav-item {
        flex: 1;
    }

    .tabsmain .nav-tabs .nav-item .nav-link {
        width: 100%;
    }

    .main-banner .parent {
        flex-direction: column-reverse;
        gap: 38px;
    }

    .main-banner .parent img {
        padding: 0;
    }

    .main-banner .bannerheadings .twice-btns {
        flex-direction: column;
    }

    .main-banner .bannerheadings h2 {
        // font-size: 24px;
    }
}

.howitworks-btn {
    color: var(--heading-text);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}


@media (max-width:1450px) {
    // .main-banner .custom-container {
    //     max-width: 1200px !important;
    // }
}

@media(max-width:991px) {
    .forcards .parentscards {
        grid-template-columns: 1fr 1fr;
    }

    .main-banner .bannerheadings .staketwobutton {
        flex-direction: column;
    }

    .main-banner .bannerheadings .staketwobutton .stake {
        max-width: 100%;
        border: none;
    }

}

@media(max-width:600px) {
    .formobileimage {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .global-bg {
        display: none !important;

    }

    .main-banner .bannerheadings .gemsmainheadinggggg {
        font-size: 55px !important;
        white-space: nowrap;
    }

    .main-banner {
        min-height: unset;
        height: 330px;

    }

    .forcards .parentscards .innercard {
        padding: 30px 15px;
        height: 138px !important;
    }

    .forcards .parentscards .innercard h6 {
        font-size: 14px;
    }

    .forcards .parentscards .innercard p {
        font-size: 12px;
    }

    .main-banner .second {
        display: none !important;
    }
    .main-banner .bannerheadings h2{
        font-size: 30px;
    }
    .main-banner .bannerheadings h2 span{
        font-size: 30px;
    }
    .main-banner .bannerheadings .bannerhead{
        font-size: 16px;
        margin-bottom: 10px;
    }
}