.main-navbar {
    // height: 69px;
    padding: 14px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    border-bottom: 1px solid var(--border);
    // background: rgba(8, 21, 25, 0.20);
    backdrop-filter: blur(15px);

    .navbar-brand {
        margin: 0;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }

    .twice-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;

        .faqs-btn {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
        }
    }
}

@media(max-width:992px) {
    .main-navbar {
        padding: 10px;
    }

    .bar-btn {
        display: block !important;
    }

    .main-navbar .twice-btns {
        display: none !important;
    }
}


.offcanvas-bar {
    min-width: 100% !important;
    border-bottom: 1px solid #142125;
    background: var(--body-bg);

    .offcanvas-header {

        .btn-close {
            background: url("../../../assets/close-btn.svg") !important;
            box-shadow: none !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain !important;
            opacity: 1 !important;
        }
    }

    .offcanvas-body {
        padding: 15px !important;
        padding-top: 30px !important;
        position: fixed;
        bottom: 20px;
        width: 100%;

        .multi-btns {
            .faqs-btn {

                border-radius: 4px;
                border: 1px solid var(--V6-Dark-Theme-Green-Color, #30F1B6);
                padding: 17px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 20px;
                color: var(--V6-Dark-Theme-Green-Color, #30F1B6);

                font-size: 14px;
                font-style: normal;
                font-family: 'hregular';
                line-height: normal;
                text-transform: uppercase;

                &:hover {
                    border-radius: 3.89px;
                    background: var(--V6-Dark-Theme-Tab-Active-Color, #0A2931);
                    border: none;
                }
            }

            .btn-disconnect {
                width: 100%;

                h6 {
                    width: 100%;
                    text-align: center;
                }
            }

            .btn-launch {
                width: 100%;

                h6 {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}



.toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 100px;
    background: #D1DBDF;
    padding: 3px;
    max-width: 55px;
}


.theme-toggle {
    display: none;
}


.toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    position: relative;
}


.icon {
    font-size: 24px;
    transition: transform 0.3s, color 0.3s;
}


.sun,
.moon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.3s;
}


.theme-toggle:not(:checked)+.toggle-label .sun {
    border-radius: 25.778px;
    background: #02171D;
    box-shadow: 0px 0px 10px 0px rgba(2, 23, 29, 0.60);


}



.theme-toggle:checked+.toggle-label .moon {
    border-radius: 25.778px;
    background: #02171D;
    box-shadow: 0px 0px 10px 0px rgba(2, 23, 29, 0.60);
}


.theme-toggle:checked~body {
    background-color: #333333;
    color: #ffffff;

}

.walletconectmodal{
    .modal-dialog{
        max-width: 500px;
        .modal-content{
            padding: 20px !important;
            .modal-header{
                padding: 0;
                border: none !important;
                padding-bottom: 20px;
                align-items: center;
                justify-content: space-between;
                display: flex;
            }
            .modal-body{
                padding: 0;
                border: none;
            }
        }
    }
}