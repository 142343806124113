.parentmain {
  .thirdclass {
    border-radius: 4px;
    background: rgba(50, 152, 121, 0.08);
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    color: rgba(50, 152, 121, 0.30);
    font-family: "hmedium";
    font-size: 12px;
    font-style: normal;
    line-height: 100%; 
    text-transform: capitalize;
    border: none;
    width: 100%;
  }

  .thirdclass2 {
    border-radius: 4px;
    background: var(--Primary-Green, #329879);
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    color: var(--Box-Background-Color, #ECF5F9);
    font-family: "hmedium";
    border: none;
    font-size: 12px;
    font-style: normal;
    line-height: 100%; 
    text-transform: capitalize;
    align-items: center;
    border: none;
    width: 100%;
  }
  .parentbar {
    border-radius: 4px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 163px;

    th {
      color: var(--th-clr);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 120%;
      letter-spacing: 0.24px;
      height: 48px;
      padding: 10px 18px;
      vertical-align: middle;
      border-radius: 0;
      border-bottom: 1px solid var(--t-brdr);
      background: var(--t-bg);
      white-space: nowrap;
    }

    .newdiv {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .headtabel {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.28px;
      text-transform: uppercase;
      height: 79px;
      padding: 10px 20px;
      border-radius: 4px;
      background: 1px solid var(--V6-Dark-Theme-Stroke, #2B3E43);
      padding-right: 10px;
    }

    .headingtable {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    .tabletext {
      color: var(--datepara);
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 110%; 
      letter-spacing: 0.1px;
    }

    .tablebody {
      border-bottom: 1px solid #1B1B1B;
      background: #000;
    }

    .secondtabletext {
      color: var(--timepara);
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 110%; 
      letter-spacing: 0.1px;
    }

    .datatableone {
      height: 80px;
      padding: 10px 20px;
      border-bottom: 1px solid var(--t-brdr) !important;
      background: var(--t-bg);
      border: none;
      outline: none;
    }

    .addclass {
      color: var(--td-clr);
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 110%; 
      letter-spacing: 0.24px;
    }

    .secondclass {
      color: #E88F35;
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 110%; 
      letter-spacing: 0.24px;
    }

    .secondclassagain {
      color: #329879;
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 110%; 
      letter-spacing: 0.24px;
    }

    .thirdclass {
      border-radius: 4px;
      background: rgba(50, 152, 121, 0.08);
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      color: rgba(50, 152, 121, 0.30);
      font-family: "hmedium";
      font-size: 12px;
      font-style: normal;
      line-height: 100%; 
      text-transform: capitalize;
      border: none;
    }

    .thirdclass2 {
      border-radius: 4px;
      background: var(--Primary-Green, #329879);
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      color: var(--Box-Background-Color, #ECF5F9);
      font-family: "hmedium";
      border: none;
      font-size: 12px;
      font-style: normal;
      line-height: 100%; 
      text-transform: capitalize;
      align-items: center;
      border: none;
    }

    .maintablehaed {
      border-radius: 4px;
      background: var(--cardbg);
    
      // border: 1px solid var(--V6-Dark-Theme-Stroke, #2B3E43);
      // background: var(--V6-Dark-Theme-Box-Background-Color, #021C24);

      table {
        width: 100%;
      }
    }
  }

  // .Paginationlattable {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 100%;
  //   padding: 13px 20px 14px 20px;
  //   background: #121212;
  //   border-bottom-left-radius: 12px;
  //   border-bottom-right-radius: 12px;
  //   backdrop-filter: blur(3px);


  //   .showclass {
  //     color: #a2a4a6;
  //     font-size: 14px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 100%;
  //     /* 14px */
  //   }

  //   .page-item:not(:first-child) .page-link {
  //     color: #a2a4a6;
  //     background-color: unset;
  //     border: unset;
  //     font-size: 15px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 100%;
  //     /* 15px */
  //     display: flex;
  //     padding: 6px 11px;
  //     gap: 10px;
  //   }



  //   .pagination {
  //     margin-bottom: unset;
  //     display: flex;
  //     align-items: center;
  //   }

  //   .page-link:focus {
  //     box-shadow: none !important;
  //   }

  //   .active>.page-link {
  //     border-radius: 5px;
  //     background: #53FFEA !important;
  //     color: #002D27 !important;
  //   }

  //   .svgclasss {
  //     padding: 6px;
  //   }
  // }

  .Paginationlattable {
    display: flex;
    padding: 10px 18px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    margin-top: 0px;

    .right-f .active>.page-link,
    .right-f .page-link.active {
      border-radius: 5px;
          background: #0A2931;
          color: #2BF7C4;
    }

    .left-f{
        h6{
            color: var(--th-clr);
font-family: "hmedium";
font-size: 12px;
font-style: normal;
line-height: 110%; 
letter-spacing: 0.1px;
        }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .active>.page-link,
      .page-link.active {
        border-radius: 5px;
        background: var(--pagactbg);
        color: var(--pagactclr);
      }

      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: var(--pagclr);
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
        background-color: transparent;
        padding: 6px 11px;
        border-radius: 5px;

        &.active {
          border-radius: 5px;
          background: var(--pagactbg);
          color: var(--pagactclr);
        }
      }

      .page-item:first-child .page-link {
        color: var(--pagclr);
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
      }
    }
  }

  .mainheadphone {
    border-radius: 4px;
    border: 1px solid var(--t-brdr);
    background: var(--t-bg);
    padding: 20px;
    margin-bottom: 174px;

    .headingstalking {
      color: #fff;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      /* 25.2px */
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    .cardone {
      padding: 15px;
      border: 1px solid var(--t-brdr);
      background: var(--t-bg);
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .dateclass {
      color: var(--amountclr);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 120%; 
      padding-bottom: 5px;
    }

    .dateclasstwo {
      color: var(--td-clr);
      font-family: "hregular";
      font-size: 12px;
      font-style: normal;
      line-height: 120%; 
    }

    .amount {
      color: var(--amountclr);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 120%; 
      padding-bottom: 5px;
    }

    .digits {
      color: var(--td-clr);
      font-family: "hregular";
      font-size: 12px;
      font-style: normal;
      line-height: 120%; 
    }

    .cooldown {
      color: var(--amountclr);
      font-family: "f37";
      font-size: 12px;
      font-style: normal;
      line-height: 120%; 
      padding-bottom: 5px;
    }

    .timer {
      color: #ff8a00;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.1px;
      padding-bottom: 20px;
    }

    .withdrawbutton {
      color: #363636;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding: 10px 30px;
      border: 1px solid #121212;
      background: #000;
      box-shadow: 2px 2px 0px 0px #121212;
      width: 100%;
    }

    .withdrawbutton1 {
      color: #fff;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      padding: 10px 20px;
      border: 1px solid #fff;
      background: #000;
      box-shadow: 2px 2px 0px 0px #fff;
      width: 100%;
    }

    .flexclass {
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      padding-bottom: 20px;
    }

    .headofcards {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.Paginationlattable2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px 11px;
  border: 1px solid #fff;
  background: #000;
  backdrop-filter: blur(3px);
  background: #000;
  backdrop-filter: blur(3px);
  margin-top: 20px;

  .showclass {
    color: #a2a4a6;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 14px */
  }

  .page-item:not(:first-child) .page-link {
    color: #a2a4a6;
    background-color: unset;
    border: unset;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 15px */
    display: flex;
    padding: 6px 11px;
    gap: 10px;
  }

  .pagination {
    margin-bottom: unset;
    display: flex;
    align-items: center;
  }

  .active>.page-link {
    color: #000 !important;
    border: unset;
    border-radius: unset;
    background: #fff !important;
  }

  .svgclasss {
    padding: 6px;
  }
}

.generic-modal {
  .paragraphmodal {
    color:var(--V6-Dark-Theme-Primary-Text-Color);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 15.4px */
  }

  .headingmodal {
    color: var(--inputmainssss);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 44px */
    text-transform: uppercase;
    padding-top: 15px;
  }

  .headmodal {
    margin: 0 auto;
    padding: 30px 0px;
    // background: url('../../../src/assets/modalcommonbg.png');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // border-radius: 16px;
    border-radius: 4px;
      border: 1px solid rgba(61, 145, 164, 0.00);
      background: var(--formodalfirst);
  }

  .divmid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .feediv {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 14.4px */
  }

  .numdiv {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 14.4px */
    letter-spacing: 0.24px;
  }

  .walletpara1 {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    padding-top: 10px;
    padding-bottom: 50px;
  }
}

.stakingmodals {
  .modal-body {
    .forbrdrs {
      border: 1px solid #fff !important;
      background: #000;
      box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 0px;
      width: 100%;
      margin-bottom: 30px;
    }

    .happy {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .formsrgin {
        // margin-top: 30px;
      }

      h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      .wrong {
        margin-top: 40px;
      }

      p {
        color: #2F2F2F;
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}

@media (max-width: 850px) {
  .parentmain .Paginationlattable{
    border-radius: 12px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .parentmain {
    .mainheadphone {
      display: block !important;
    }

    .parentbar {
      display: none;
    }

    .secondclass {
      color: #E88F35;
      font-family: "hbold";
      font-size: 14px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .secondclassagain {
      color: #329879;
      font-family: "hbold";
      font-size: 14px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }
}


.thirdclass.lklklklklklk {
  color: #363636;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 30px;
  border: 1px solid #121212;
  background: #000;
  box-shadow: 2px 2px 0px 0px #121212;
  outline: none;
}

.thirdclass2.lklklklklklk {
  padding: 10px 30px;
  border: 1px solid #fff;
  background: #000;
  box-shadow: 2px 2px 0px 0px #fff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  outline: none;
}


.parentmain .stakingstatbox {
  border-radius: 4px;
  background: var(--cardbg);
  padding: 60px 30px;
  border: none;
}

.parentmain .stakingstatbox .wrapper .whitetext {
  color: var(--heading-text);
  font-family: "hmedium";
  font-size: 20px;
  font-style: normal;
  line-height: 120%;
  text-transform: capitalize;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}


.stakingstatbox {
  border-radius: 12px;
  border: 1px solid #252525;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(12px);
  padding: 60px 394px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .whitetext {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-top: 20px;
      margin-bottom: 45px;
      width: 478px;
    }

    .waletconnectss {
      border-radius: 4px;
      background: var(--checkbg);
      padding: 18px 22px;
      color: var(--bgbgbg);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: "hregular";
      line-height: 120%;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      border: none;
    }
  }
}

.unstaking-main-section {
  padding-bottom: 100px;
}