@font-face {
  font-family: 'f37';
  src: url('../src/font/incise/f37incisemedium.ttf');
}

@font-face {
  font-family: 'bl';
  src: url('../src/font/blarctic/blarctic.otf');
}

@font-face {
  font-family: 'hlight';
  src: url('../src/font/helveteca/HelveticaNowDisplay-Light.ttf');
}

@font-face {
  font-family: 'hregular';
  src: url('../src/font/helveteca/HelveticaNowDisplay-Regular.ttf');
}

@font-face {
  font-family: 'hmedium';
  src: url('../src/font/helveteca/HelveticaNowDisplay-Medium.ttf');
}

@font-face {
  font-family: 'hsemibold';
  src: url('../src/font/helveteca/HelveticaNowDisplay-Bold.ttf');
}

@font-face {
  font-family: 'hbold';
  src: url('../src/font/helveteca/HelveticaNowDisplay-ExtraBold.ttf');
}

.dark-theme {

  // common style..............
  --body-bg: #081519;
  --heading-text: #E1F5EB;
  --text-color: #E1EFF5;
  --V6-Dark-Theme-Primary-Text-Color: #D1DADD;
  --V6-Dark-Theme-Secondary-Text-Color: #939FA3;
  --V6-Dark-Theme-Green-Color: #30F1B6;
  --border: #2D4850;
  --border1: #0F2931;
  --border2: #142125;
  --svg-fill: #D1DADD;
  --btn-green-bg: #30F1B6;
  --btn-green-text: #081519;
  --btn-transparent-border: #30F1B6;
  --btn-transparent-text: #30F1B6;
  --btn-seeall-border: #2EFFCB;
  --btn-seeall-text: #E1EFF5;
  --card-bg: #081519;
  --tab-text: #fff;
  --token-card-bg: rgba(9, 24, 29, 0.97);
  --card-bg2: rgba(9, 24, 29, 0.95);
  --btn-buy-bg: #09181D;
  --btn-buy-border: #172327;
  --btn-buy-text: #30F1B6;
  // navbar..............
  --nav-bg: rgba(8, 21, 25, 0.20);
  --nav-active: #30F1B6;
  --nav-link: #8A989D;
  --d-toggle-bg: rgba(255, 255, 255, 0.04);
  --d-body-link-bg: #031F27;
  --d-body-link-bg-hov: #031F27;
  --d-body-link-text-hov: #D1DADD;
  --d-body-link-text: #939FA3;
  --para: #939FA3;
  --cardtextmain: #E1F5EB !important;
  // all others.................
  --crypto-heading-bg: rgba(8, 21, 25, 0.70);
  --background: #000;
  --background2: rgba(8, 21, 25, 0.50);
  --cardbg: var(--V6-Dark-Theme-Box-Background-Color, #021C24);
  --tabbackground: var(--V6-Dark-Theme-Tab-Background-Color, #02171D);
  --tabbg: var(--V6-Dark-Theme-Tab-Active-Color, #0A2931);
  --tabbgdull: var(--V6-Dark-Theme-Tab-Background-Color, #02171D);
  --bgbgbg: #081519;
  --transprentborder: transparent;
  --metamaskbg: #0A2931;
  --hoverbg: rgba(48, 241, 182, 0.20);
  --metatext: var(--Box-Background-Color, #ECF5F9);
  --cardlighttext: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
  --stakinginnercard: var(--V6-Dark-Theme-Tab-Active-Color, #0A2931);
  --stakingcardbutton: rgba(43, 247, 196, 0.05);
  --stakingbuttontext: var(--V6-Dark-Theme-Green-Color, #30F1B6);
  --buttonbrdr: var(--V6-Dark-Theme-Stroke, #2B3E43);
  --buutonbrdrtext: var(--V6-Dark-Theme-Primary-Text-Color, #D1DADD);
  --unstaketext: var(--V6-Dark-Theme-Green-Color, #30F1B6);
  --forhovergreen: rgba(43, 247, 196, 0.15);
  --forfocusgreen: rgba(43, 247, 196, 0.05);
  --forfocusgreenbrdr: #2BF7C4;
  --btnunstakehover: var(--V6-Dark-Theme-Tab-Active-Color, #0A2931);
  --forfocusstake: #2BF7C4;
  --forshadfocus: 0px 0px 17px 8px rgba(40, 203, 163, 0.25);
  --formodalfirst: #0A2931;
  --formodalfirsthov: #083D3C;
  --formodalfirstfocus: #2BF7C4;
  --inputmainssss: var(--Primary-Text-Color, #D1DADD);
  --formodaltextttt: var(--Primary-Text-Color, #D1DADD);
  --forlightmodaltext: var(--Primary-Text-Color, #D1DADD);
  --lastmodal: var(--V6-Dark-Theme-Tab-Active-Color, #0A2931);
  --t-bg: #021C24;
  --th-clr: #939FA3;
  --td-clr: #E1EFF5;
  --t-brdr: #2B3E43;
  --datepara: #D1DADD;
  --timepara: #939FA3;
  --pagclr: #939FA3;
  --pagactclr: #2BF7C4;
  --pagactbg: #0A2931;
  --amountclr: #939FA3;
--newmodalbg: #021C24;
--checkbg: #2BF7C4;
}

.light-theme {
  --body-bg: #E2E9EC;
  --heading-text: #01141A;
  --text-color: #02171D;
  --V6-Dark-Theme-Primary-Text-Color: #081519;
  --V6-Dark-Theme-Secondary-Text-Color: #01141A;
  --V6-Dark-Theme-Green-Color: #084;
  --border: #C1CBCE;
  --border1: #C1CBCE;
  --border2: #C1CBCE;
  --svg-fill: #081519;
  --btn-green-bg: #329879;
  --btn-green-text: #E1E9EC;
  --btn-transparent-border: #081519;
  --btn-transparent-text: #081519;
  --btn-seeall-border: #329879;
  --btn-seeall-text: #01141A;
  --card-bg: rgba(209, 218, 221, 0.70);
  --tab-text: #01141A;
  --token-card-bg: rgba(205, 215, 218, 0.97);
  --card-bg2: rgba(205, 215, 218, 0.95);
  --btn-buy-bg: transparent;
  --btn-buy-border: #081519;
  --btn-buy-text: #081519;
  // navbar..............
  --nav-bg: rgba(8, 21, 25, 0.20);
  --nav-active: #329879;
  --nav-link: #4F5C61;
  --d-toggle-bg: rgba(8, 21, 25, 0.07);
  --d-body-link-bg: #ECF5F9;
  --d-body-link-bg-hov: #ECF5F9;
  --d-body-link-text-hov: #081519;
  --d-body-link-text: #869397;
  --cardtextmain: var(--V6-Dark-Theme-Box-Background-Color, #021C24);
  --cardbg: var(--Box-Background-Color, #ECF5F9);

  --para: var(--Secondary-Text-Color, #77868B);

  // all others.................
  --crypto-heading-bg: #D1DADD;
  --background: #fff;
  --background2: rgba(205, 215, 218, 0.50);
  --forcardss: var(--Box-Background-Color, #ECF5F9);
  --tabbackground: #E1E9EC;
  --tabbg: var(--Tab-Active-Color, #C1ECE3);
  --tabbgdull: #ECF5F9;
  --bgbgbg: #E2E9EC;
  --transprentborder: #C1CBCE;
  --metamaskbg: #ECF5F9;
  --hoverbg: #C8D4D8;
  --metatext: var(--Light-Mode-Primary-Text-Color, #02171D);
  --cardlighttext: var(--Secondary-Text-Color, #77868B);
  --stakinginnercard: #E7EEF2;
  --stakingcardbutton: var(--Text-Field-Background, #D1DBDF);
  --stakingbuttontext: var(--Primary-Text-Color, #02171D);
  --buttonbrdr: var(--Text-Field-Background, #D1DBDF);
  --buutonbrdrtext: var(--Primary-Green, #329879);
  --unstaketext: var(--Primary-Green, #329879);
  --forhovergreen: rgba(48, 199, 153, 0.25);
  --forfocusgreen: rgba(48, 199, 153, 0.00);
  --forfocusgreenbrdr: #329879;
  --btnunstakehover: rgba(48, 199, 153, 0.25);
  --forfocusstake: #30C799;
  --forshadfocus: 0px 0px 17px 8px rgba(48, 199, 153, 0.25);
  --formodalfirst: #D1DBDF;
  --formodalfirsthov: rgba(209, 219, 223, 0.25);
  --formodalfirstfocus: #30C799;
  --inputmainssss: var(--Text-Field-Placeholder-Color, #69797E);
  --formodaltextttt: var(--Primary-Text-Color, #02171D);
  --forlightmodaltext: var(--Primary-Text-Color, #02171D);
  --lastmodal: #ECF5F9;
  --t-bg: #ECF5F9;
  --th-clr: #77868B;
  --td-clr: #02171D;
  --t-brdr: #C0CBCF;
  --datepara: #02171D;
  --timepara: #77868B;
  --pagclr: #869397;
  --pagactclr: #329879;
  --pagactbg: #C1EBE2;
  --amountclr: #C0CBCF;
  --newmodalbg: #E2E9EC;
  --checkbg: #329879;
}

.light-theme .show-in-light-theme {
  display: block !important;

}

.light-theme .hide-in-light-theme {
  display: none !important;
}


html {
  scroll-behavior: smooth;
}

body {
  background: var(--body-bg);
  min-height: 100vh;
  position: relative;


  &::-webkit-scrollbar {
    width: 5px;
  }


  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #808080;
  }
}

.nav-container {
  max-width: 1460px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

@media (max-width:600px) {
  body {
    padding: 0px;
  }

  .nav-container {
    max-width: 1460px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }
}

.custom-container {
  max-width: 1350px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}



.btn-launch {
  // padding: 6px 25px;
  padding: 12px 22px;
  color: var(--btn-green-text);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-family: "hmedium";
  line-height: 120%;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  height: 42px;
  border: none;

  border-radius: 4px;
  background: var(--nav-active);


}


.btn-disconnect {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--btn-green-bg);
  color: var(--btn-green-text);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-family: 'hregular';
  line-height: 120%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
  padding: 6px 25px;
  height: 42px;
  border: none;

}




.Appp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerr {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  width: 100vw;
}

.sectionn {
  flex-shrink: 0;
  flex-grow: 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
}

.vertical-scrolll {
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal-scrolll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.itemm {
  flex: 0 0 auto;
  width: 200px;
  /* Adjust width as needed */
  height: 200px;
  /* Adjust height as needed */
  margin: 10px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}


.modal-backdrop {
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(5px);
}

.generic-modal {
  .modal-dialog {
    max-width: 500px !important;

    .modal-content {
      padding: 0px 20px 20px 20px;
      // border-radius: 12px;
      // background: #FFF;
      border-radius: 4px;
      background: var(--cardbg);

      .modal-header {
        // border-radius: 12px;
        // border: 1px solid #E6E6E6;
        // background: #FFF;
        // box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
        // padding: 15px;
        // margin-bottom: 20px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // border-bottom:1px solid #2B3E43;
        // margin-bottom: 20px;
        padding: 20px 0px;
        border-bottom: none !important;
        // border-bottom: 1px solid var(--transprentborder) !important;



        .modal-title {
          color: var(--V6-Dark-Theme-Primary-Text-Color, #D1DADD);
          font-family: 'f37';
          font-size: 18px;
          font-style: normal;

          line-height: 115%;
          /* 20.7px */
          text-transform: capitalize;
        }

        .btn-close {
          background: url("../src/assets/btnclose.png");
          background-size: cover;
          margin: 0px;
          background-position: center;
          background-repeat: no-repeat;
          border: none;
          border: none;
          box-shadow: none;
          padding: 0;
          outline: none;
          opacity: unset;
          width: 24px;
          height: 24px;
          margin: 0;
          position: absolute;
          top: 20px;
          right: 20px;
          border: none;
          box-shadow: none;
        }
      }

      .modal-body {
        // padding: 0px;
        border-top: 1px solid #2B3E43;
        // margin-bottom: 20px;
        padding: 20px 0px;

        .setmargin {
          margin-bottom: 16px;
        }


        .mainbtns {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 15px;

          .diabledbtn {
            border: 1px solid #121212;
            background: #000;
            box-shadow: 4px 4px 0px 0px #121212;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            color: #363636;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            width: 100%;
            text-transform: uppercase;
          }

          .dullbtn {
            border-radius: 4px;
            background: var(--formodalfirst);
            display: flex;
            padding: 18px 40px;
            justify-content: center;
            align-items: center;
            color: var(--V6-Dark-Theme-Primary-Text-Color);
            font-size: 14px;
            font-style: normal;
            font-family: 'hmedium';
            line-height: 100%;
            text-transform: capitalize;
            width: 100%;
            border: none;

            &:hover {
              background: var(--formodalfirsthov);
            }

            &:focus {
              background: var(--formodalfirstfocus);
            }
          }

          .brdrbtn {
            border-radius: 4px;
            background: var(--btn-green-bg);
            color: var(--btn-green-text);
            display: flex;
            padding: 18px 40px;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-family: 'hmedium';
            line-height: 100%;
            text-transform: capitalize;
            width: 100%;
            border: none;
          }
        }

        .commonbtn {
          border-radius: 4px;
          background: var(--btn-green-bg);
          color: var(--btn-green-text);
          border: none;
          display: flex;
          padding: 18px 40px;
          justify-content: center;
          align-items: center;
          // color: var(--Button-Text, #02171D);
          font-size: 14px;
          font-style: normal;
          font-family: 'hmedium';
          line-height: 100%;
          text-transform: uppercase;
          width: 100%;
        }

        .walletmains {
          display: grid;
          grid-template-columns: 1fr 1fr;
          border-radius: 4px;
          overflow: hidden;

          .walletinner {
            display: flex;
            padding: 50px 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 12px;
            // border-radius: 12px;
            // border: 1px solid #F2F2F2;
            // background: #FFF;
            border: 1px solid var(--transprentborder);
            background: var(--metamaskbg);
            width: 100%;
            cursor: pointer;

            .walletpara {
              color: var(--metatext);
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-family: 'hmedium';
              line-height: 100%;
            }

            &:hover {
              transition: 0.5s;
              background: var(--hoverbg);
            }
          }
        }
      }
    }
  }
}

.dark-toast .Toastify__toast--default {
  background: #333;
  color: #fff;
}

.asdasdasdasd {
  font-size: 20px !important;
  margin-bottom: 10px;
}

.sdsdfadsfdsfsdf {
  margin-top: 10px;
  font-size: 18px !important;
}

.buttonblur {
  pointer-events: none;
  opacity: 0.4;
}

.sdjsgdfhsdgfh {
  // pointer-events: none;
  opacity: 0.4;
}

.sjdgsdhjgdsjf {
  color: var(--V6-Dark-Theme-Primary-Text-Color) !important;
  margin-top: 20px !important;
  font-family: 'f37' !important;
}


@media(max-width:768px) {
  .sddsdsdsdsdsds {
    display: none !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.loadertext {
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.1px;
  margin-top: 20px;
}

.flexdirectioncol {
  flex-direction: column !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}


.sjdasjashdjasdjgsa {
  text-align: left !important;
}


@media (max-width:991px) {
  .generic-modal .modal-dialog .modal-content .modal-body .walletmains {
    grid-template-columns: auto;
  }
}



.mycheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  .innercheckboxpara {
    color: var(--datepara);

    font-size: 14px;
    font-style: normal;
    font-family: 'f37';
    line-height: 120%;
    margin: 0 !important;

    a {
      color: var(--datepara) !important;
      text-decoration: underline !important;
      font-family: 'f37';
    }
  }

  .innercheckbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .innercheckbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .innercheckbox .innercheckmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    transition: all 0.2s ease;
    border: 1px solid #D1DADDB2;
    background: rgba(255, 255, 255, 0.00);
    border-radius: 4px;

    &:hover {
      border: 1px solid #D1DADD;
      border-radius: 4px;
    }
  }

  .innercheckbox input:checked~.innercheckmark {
    background: var(--checkbg);
    border: 1px solid transparent;
  }



  .innercheckbox .innercheckmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 5.5px;
    height: 12px;
    border: solid var(--cardbg);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
  }

  .innercheckbox input:checked~.innercheckmark::after {
    opacity: 1;
    transition: all 0.2s ease;
  }
}


.howitworks-content {

  h5 {
    color: var(--text-color);

    font-size: 14px;
    font-style: normal;
    font-family: 'f37';
    line-height: 120%;
  }

  ul {
    li {
      color: var(--text-color);
      font-size: 14px;
      font-style: normal;
      font-family: 'hregular';
      line-height: 120%;
      list-style-type: disc;
      margin: 12px 0;
      margin-left: 15px;
    }
  }

  h6 {
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-family: 'f37';
    line-height: 120%;
  }

  .para {
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-family: 'hregular';
    line-height: 120%;
    margin: 15px 0;
  }

  .mycheckbox {
    justify-content: start !important;
    margin: 20px 0;
  }
}

.howitworksmodal {
  .modal-dialog {
    max-width: 610px !important;

    .modal-content {
      padding: 0 !important;
      background: var(--newmodalbg);

      .modal-header {
        padding: 27px 30px 21px !important;
        .modal-title{
          font-size: 20px !important;
        }
      }
      .modal-body{
        padding: 23px 29px 27px !important;
      }
    }
  }


  .btn-accept {
    border-radius: 4px;
    background: var(--checkbg);

    padding: 10px 10px 10px 12px;
    color: var(--cardbg);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-family: 'hsemibold';
    line-height: normal;
    text-transform: capitalize;
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(5px);
}

.howitworks-content {
  .forboxstyle {
    padding: 20px;
    border-radius: 4px;
    background: var(--lastmodal);
  }

}

// .forheightset {
//   position: relative;
//   z-index: 9;

//   .global-bg {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1;
//     pointer-events: none;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }

.light-theme .generic-modal .modal-dialog .modal-content .modal-header .btn-close {
  background: url(../src/assets/btnclose-light.svg);
  background-size: cover;
  margin: 0px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border: none;
  box-shadow: none;
  padding: 0;
  outline: none;
  opacity: unset;
  width: 24px;
  height: 24px;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  box-shadow: none;
}