.main-footer {
    margin-top: 20px;
    // border: 1px solid #1B1B1B;
    // background: #000;
    border-top: 1px solid var(--border2);
backdrop-filter: blur(15px);
  padding: 17px 60px;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: var(--V6-Dark-Theme-Secondary-Text-Color, #939FA3);
            font-family: 'hregular';
            font-size: 14px;
            font-style: normal;

            line-height: 140%;
        }
    }
}

@media (max-width:600px) {
    .main-footer {
        padding: 29px 0px;
        border: none;
        border-top: 1px solid #1B1B1B;
    }
    .main-footer .parent {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}