.datatable {
    .main-heading {
        margin: 30px 0;

        h6 {
            color: #000;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.44px;
        }
    }

    .table-responsive {
        border-radius: 4px;
        // border: 1px solid var(--V6-Dark-Theme-Green-Color, #30F1B6);
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        &::-webkit-scrollbar {
            display: none;
        }

        .table {
            margin: 0;
        }

        th {
            color: var(--th-clr);
            font-family: "f37";
            font-size: 12px;
            font-style: normal;
            line-height: 120%;
            letter-spacing: 0.24px;
            height: 48px;
            padding: 10px 18px;
            vertical-align: middle;
            border-radius: 0;
            border-bottom: 1px solid var(--t-brdr);
            background: var(--t-bg);
            white-space: nowrap;
        }

        td {
            color: var(--td-clr);
            font-family: "hmedium";
            font-size: 12px;
            font-style: normal;
            line-height: 110%; 
            letter-spacing: 0.24px;
            height: 54px;
            padding: 10px 18px;
            vertical-align: middle;
            border-bottom: 1px solid var(--t-brdr);
            background: var(--t-bg);

            .hashpara {
                color: var(--td-clr);
                font-family: "hmedium";
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.1px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                letter-spacing: 0.1px;
            }

            .stakingdatesmain {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                gap: 2px;

                .datepara {
                    color: var(--datepara);
                    font-family: "hmedium";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 110%;
                    letter-spacing: 0.1px;
                }

                .timepara {
                    color: var(--timepara);
                    font-family: "hmedium";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 110%; 
                    letter-spacing: 0.1px;
                }
            }

            .unstakebtn {
                color: var(--Black, #000);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                display: flex;
                padding: 12px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                background: #F8F8F8;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
                border: none;
                outline: none;
            }
        }
    }

    .footer-content {
        display: flex;
        padding: 10px 18px;
        justify-content: space-between;
        align-items: center;
        border-radius: 0;
        margin-top: 0px;
    
        .right-f .active>.page-link,
        .right-f .page-link.active {
          border-radius: 5px;
              background: #0A2931;
              color: #2BF7C4;
        }

        .left-f{
            h6{
                color: var(--th-clr);
font-family: "hmedium";
font-size: 12px;
font-style: normal;
line-height: 110%; 
letter-spacing: 0.1px;
            }
        }
    
        .right-f {
          display: flex;
          justify-content: flex-end;
          align-items: center;
    
          .active>.page-link,
          .page-link.active {
            border-radius: 5px;
            background: var(--pagactbg);
            color: var(--pagactclr);
          }
    
          .page-link:focus {
            box-shadow: none;
            background-color: unset;
          }
    
          .page-link:hover {
            box-shadow: none;
            background-color: unset;
          }
    
          .page-link {
            border: none;
            color: var(--pagclr);
            font-family: "f37";
            font-size: 15px;
            font-style: normal;
            line-height: 100%;
            background-color: transparent;
            padding: 6px 11px;
            border-radius: 5px;
    
            &.active {
              border-radius: 5px;
              background: var(--pagactbg);
              color: var(--pagactclr);
            }
          }
    
          .page-item:first-child .page-link {
            color: var(--pagclr);
            font-family: "f37";
            font-size: 15px;
            font-style: normal;
            line-height: 100%;
          }
        }
      }

    .more-btn {
        .dropdown-toggle {
            border: none;
            background-color: transparent;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            border: 1px solid #e6e6e6;
            background: #fff;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            padding: 0px;
            margin: 0px;
            max-height: 200px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none !important;
            }

            .dropdown-item {
                padding: 15px 15px;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                overflow: hidden;
                border-bottom: 1px solid #e6e6e6 !important;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background: #FAFAFA;
                }
            }
        }
    }
}


.filter-btn {
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}

.parent-tag {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width:650px) {


    .accmblview {
        display: block !important;
        border-radius: 12px;
        border: 1px solid #E6E6E6;
        background: #FFF;

        .main-twice-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 28px;
            padding-right: 61px;

            .accmblviewhead {
                color: #000;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.28px;
                display: flex;
                justify-content: flex-start;
                align-items: center;


                .arrowsacc {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }



        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                // background: url("../../../../assets/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
                position: absolute;
                top: 28px;
                right: 25px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;


                    .accordion-button {
                        border-top: 1px solid #FFF;
                        border-bottom: 1px solid #FFF;
                        background: var(--login-bg);
                        padding: 22px 25px;
                        padding-right: 61px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 30px;
                            width: 100%;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 0px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 0px;
                        border-bottom: 1px solid #1C1C1C;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                            text-transform: uppercase;
                        }

                        .textright {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 21px;
                            letter-spacing: 0.28px;
                        }

                        .brdrbtn {
                            border: 1px solid #FFF;
                            background: #000;
                            box-shadow: 2px 2px 0px 0px #FFF;
                            display: flex;
                            padding: 7px 15px;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .twice-text {
            h6 {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
            }

            p {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                /* 175% */
                letter-spacing: 0.24px;
                margin-top: 2px;
            }
        }
    }

    .accmblview .accordion .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
    }

    // .footer-content {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     padding: 13px 20px;
    //     background: #121212;
    //     backdrop-filter: blur(3px);
    //     border-bottom-left-radius: 12px;
    //     border-bottom-right-radius: 12px;

    //     .right-f .active > .page-link, .right-f .page-link.active{
    //         border-radius: 5px;
    //         background: #53FFEA !important;
    //         color: #002D27 !important; 
    //       }

    //     .left-f {
    //         h6 {
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 14px;
    //             line-height: 100%;
    //             color: #A0A1A1;
    //         }
    //     }

    //     .right-f {
    //         .page-link:focus {
    //             box-shadow: none;
    //             background-color: unset;
    //         }

    //         .page-link:hover {
    //             box-shadow: none;
    //             background-color: unset;
    //         }

    //         .page-link {
    //             border: none;
    //             color: #A2A4A6;
    //             font-size: 15px;
    //             font-style: normal;
    //             font-weight: 700;
    //             line-height: 100%;
    //             /* 15px */
    //             background-color: transparent;

    //             &.active {
    //                 border-radius: 5px;
    //                 background: #000;
    //                 box-shadow: 0px -4px 0px 0px rgba(255, 255, 255, 0.20) inset;
    //                 color: #fff;
    //             }
    //         }

    //         .page-item:first-child .page-link {
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 15px;
    //             line-height: 100%;
    //             color: #A0A1A1;
    //         }
    //     }
    // }

    .dashboardetail .auctiondetailhead {
        font-size: 20px;
    }
}