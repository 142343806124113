.mystaking {
  padding-bottom: 100px;

  .stakingstatbox {
    border-radius: 12px;
    border: 1px solid #252525;
    border-radius: 4px;
    background: var(--cardbg);
    padding: 60px 30px;
border: none;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .whitetext {
        color: var(--heading-text);
        font-family: "hmedium";
        font-size: 20px;
        font-style: normal;
        line-height: 120%;
        text-transform: capitalize;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
        width: 478px;
      }

      .waletconnectss {
        border: none;
  
        border-radius: 4px;
        background: var(--checkbg);
        padding: 18px 22px;
        color: var(--bgbgbg);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-family: 'hregular';
        line-height: 120%;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        border: none;

      }
    }
  }

  .mystakingmain {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    .leftstaking {
      border-radius: 4px;
      background: var(--cardbg);
      max-width: 530px;
      width: 100%;
      padding: 30px;
      position: sticky;
      top: 15px;

      .leftstakinghead {
        color:var(  --text-color);
        font-size: 26px;
        font-style: normal;
        font-family: 'f37';
        line-height: 120%;
        // border-bottom: 1px solid #1e1e1e;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 0px;
        border: none !important;
      }

      .rewardbox {
        border: 1px solid #1b1b1b;
        background: #0d0d0d;
        padding: 20px;

        .reard {
          color: #868686;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 7px;
        }

        h4 {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 24px;
        }

        .claimssss {
          border: 1px solid #151515;
          background: rgba(255, 255, 255, 0.2);
          padding: 10px 30px;
          width: 100%;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }

    .rightstaking {
      border-radius: 4px;
      background: var(--cardbg);
      padding: 25px;
      width: 100%;

      .rightstakinghead {
        color: var(--text-color);
        font-size: 18px;
        font-style: normal;
        font-family: 'f37';
        line-height: 140%;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
}

.stakingmodals {
  .modal-header{
    // border-bottom: 1px solid #2B3E43;
  }
  .modal-body {
    .forbrdrs {
      // border: 1px solid #fff !important;
      // background: #000;
      // box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 156px;
      width: 100%;
      margin-bottom: 30px;
    }

    .forbrdr {
      // border: 1px solid #fff !important;
      // background: #000;
      // box-shadow: 4px 4px 0px 0px #fff;
      padding: 30px 156px;
      width: 100%;
    }

    .stakecard {
      // background: url('../../../src/assets/modalcommonbg.png');
      // background-repeat: no-repeat;
      // background-position: center;
      // background-size: cover;
      // border-radius: 16px;
      border-radius: 4px;
      border: 1px solid rgba(61, 145, 164, 0.00);
      background: var(--formodalfirst);
      padding:20px;
      width: 100%;

      .textcard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .parent {
          width: 100%;

          input {
            background: transparent;
            padding: 0px;
            color:var(--inputmainssss);
            border: none;
            height: 44px;
            text-align: start;
            font-size: 32px;
            font-style: normal;
            font-family: 'f37';
            line-height: 110%;
            text-transform: uppercase;
            width: 100%;

            &::placeholder {
              color: var(--inputmainssss);
              text-align: start;
              font-size: 32px;
              font-style: normal;
              font-family: 'f37';
              line-height: 110%;
              text-transform: uppercase;
            }
          }

          .uppertext {
            color: var(--V6-Dark-Theme-Primary-Text-Color);

            text-align: center;
            // white-space: nowrap;
            font-size: 12px;
            font-style: normal;
            font-family: 'hregular';
            line-height: 110%;
            margin-bottom: 15px;
            position: relative;
          }

          .para {
            color: var(--inputmainssss);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-family: 'f37';
            line-height: 110%;
            text-transform: uppercase;
            word-break: break-all;
          }

          .max {
            border-radius: 4px;
background: var( --btn-green-bg);
padding: 8px 20px;
max-width: 71px;
width: 100%;
color: var( --cardtextmain) !important;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-family: 'hmedium';
            line-height: 120%;
            letter-spacing: 0.24px;
            position: absolute;
            top: 75px;
            right: 15px;
          }
        }
      }
    }

    .textparent {
      margin: 30px 0px;

      .innercontent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .wallet {
          color: var(  --para);
          font-size: 14px;
          font-style: normal;
          font-family: 'hregular';
          line-height: 120%;
        }

        .textpara {
          color: var(--formodaltextttt);
          font-size: 14px;
          font-style: normal;
          font-family: 'hmedium';
          line-height: 120%;
          letter-spacing: 0.24px;
        }
      }
    }

    .yellowbox {
      border: none;
      border-radius: 4px;
      background: rgba(232, 143, 53, 0.15);
      padding: 20px;
      width: 100%;
      margin-bottom: 30px;

      .cool {
        color: #E88F35;
        font-size: 12px;
        font-style: normal;
        font-family: 'f37';
        line-height: 120%;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .yellowpara {
        color: #E88F35;
        font-size: 12px;
        font-style: normal;
        font-family: 'hmedium';
        line-height: 120%;
        letter-spacing: 0.24px;
      }
    }

    .mainbtns {
      .btndisable {
        border: 1px solid #121212;
        background: #000;
        box-shadow: 4px 4px 0px 0px #121212;
        display: flex;
        padding: 15px 40px;
        justify-content: center;
        align-items: center;
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        width: 100%;
      }
    }

    .happy {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .formsrgin {
        // margin-top: 30px;
      }

      h6 {
        color: var(--forlightmodaltext);
        font-size: 40px;
        font-style: normal;
        font-family: 'f37';
        line-height: 120%;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      .wrong {
        margin-top: 20px;
      }

      p {
        color: var( --para);
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-family: 'hregular';
        line-height: 140%;
      }
    }
  }
}

@media (max-width: 1100px) {
  .mystaking .mystakingmain {
    flex-direction: column;
  }

  .mystaking .mystakingmain .leftstaking {
    position: unset;
    top: unset;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .mystaking .mystakinghead {
    font-size: 24px;
  }

  .mystaking .mystakingmain .leftstaking .leftstakinghead {
    font-size: 24px !important;
  }

  .mystaking .mystakingmain .leftstaking {
    padding: 20px;
  }

  .mystaking .mystakingmain .leftstaking .stakingdetailmain {
    flex-direction: column;
    gap: 25px;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain {
    padding: 15px;
    flex-direction: column;
    gap: 25px;

    .rewardsleft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsleft .rewardleftpara {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsleft .rewardlefthead {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsrightparas .yellowpara {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsrightparas .yellowhead {
    text-align: center;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright .restakebtn {
    width: 100%;
    border: 1px solid #151515;
    background: #0d0d0d;
    padding: 15px 30px;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright .claimbtn {
    width: 100%;
  }

  .mystaking .mystakingmain .leftstaking .rewardsmain .rewardsright {
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }

  .mystaking .mystakingmain .rightstaking .mytable {
    display: none;
  }

  .mystaking .mystakingmain .rightstaking .mytablemobile {
    display: block !important;
    border-radius: 4px;
    background: var(--t-bg);
    padding: 20px;
    .mbltable {
      border-radius: 4px;
      border: 1px solid var(--t-brdr);
      background: var(--t-bg);
      display: flex;
      padding: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 19px;
      margin-bottom: 10px;

      .mbltableinner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .mbltabledetail {
          width: 100%;

          .mblpara {
            color: var(--timepara);
            font-family: "f37";
            font-size: 12px;
            font-style: normal;
            line-height: 120%;
            margin-bottom: 5px;
          }

          .mblhead {
            color: var(--td-clr);
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 120%; 
          }

          .mblheadlink {
            color: var(--td-clr);
            font-family: "hregular";
            font-size: 12px;
            font-style: normal;
            line-height: 120%; 
            letter-spacing: 0.1px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }

  .mystaking .mystakingmain .rightstaking {
    padding: 20px;
  }

  .footer-content {
    display: flex;
    padding: 13.277px 13.5px 12.723px 13.5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--t-bg);
    margin-top: 20px;

    .right-f .active>.page-link,
    .right-f .page-link.active {
      border-radius: 5px;
      background: var(--pagactbg);
      color: var(--pagactclr);
    }

    .right-f {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .active>.page-link,
      .page-link.active {
        border-radius: 5px;
        background: var(--pagactbg);
        color: var(--pagactclr);
      }

      .page-link:focus {
        box-shadow: none;
        background-color: unset;
      }

      .page-link:hover {
        box-shadow: none;
        background-color: unset;
      }

      .page-link {
        border: none;
        color: var(--pagclr);
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
        background-color: transparent;
        padding: 6px 11px;
        border-radius: 5px;

        &.active {
          border-radius: 5px;
          background: var(--pagactbg);
          color: var(--pagactclr);
        }
      }

      .page-item:first-child .page-link {
        color: var(--pagclr);
        font-family: "f37";
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
      }
    }
  }

  .mystaking .stakingstatbox {
    padding: 60px 20px;
  }

  .mystaking .stakingstatbox .wrapper .whitetext {
    font-size: 20px;
    width: unset;
    text-align: center;
  }
}